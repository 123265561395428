/*
 * 업무구분: 계약관리
 * 화 면 명: MSPPM891M
 * 화면설명: 제휴계약
 * 작 성 일: 2022.12.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp"  title="제휴계약" :show-title="false" type="subpage" action-type="search"  @on-scroll-bottom="fn_LoadingData()" :topButton="true">
     <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">

      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">

          <!-- 헤더 타이틀 영역 -->
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary" :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button class="btn_popup_back" @click="fn_HistoryBack()"/>
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack()">
                <mo-icon color="white" icon-size="36px" borderless>drawer</mo-icon>
              </div>
              <div class="ui-gnb-title__main">{{scrollRate > 0.5 ? '제휴계약' : '제휴계약'}}</div>
              <div slot="action" @click="fn_OpenMSPPM892P">
                <mo-icon>search</mo-icon>
              </div>
            </mo-top-bar>
          </template> 
          <!-- 헤더 타이틀 영역 //--> 

          <!-- collapsing 영역 -->
          <template #collapsing>
            <p class="text-info-box">제휴계약은 삼성카드 가입고객(피보험자)에게 무료로 제공하는 보장서비스입니다. 고객조회를 원하시는 경우 고객명을 클릭하세요.</p>
          </template>
          <!-- collapsing 영역 //-->

          <!-- stickey 영역 -->
          <template #sticky>
            <!-- 검색결과 카테고리 출력 -->
            <ur-box-container v-if="showReturnStr && !isInit" alignV="start"  direction="column" class="search-result-box bd-b-Ty1">
              <strong class="tit">검색결과</strong>
              <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
                <mo-tab-label idx="1" v-if="isMngr && pOrgData891M !== {}" @click="fn_OpenMSPPM892P">
                  <span class="txt">{{pOrgData891M.hofOrg.label}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-if="isMngr && pOrgData891M !== {}" @click="fn_OpenMSPPM892P">
                  <span class="txt">{{pOrgData891M.dofOrg.label}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-if="isMngr && pOrgData891M !== {}" @click="fn_OpenMSPPM892P">
                  <span class="txt">{{pOrgData891M.fofOrg.label}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-if="isMngr && pOrgData891M !== {}" @click="fn_OpenMSPPM892P">
                  <span class="txt">{{pOrgData891M.cnslt.label}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPPM892P()">
                  <span class="tit">피보험자명</span>
                  <span class="txt">{{lv_ReturnStr[0]}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[1] !== ''" @click="fn_OpenMSPPM892P()">
                  <span class="tit">계약자명</span>
                  <span class="txt">{{lv_ReturnStr[1]}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[2] !== ''" @click="fn_OpenMSPPM892P()">
                  <span class="tit">상품명</span>
                  <span class="txt">{{lv_ReturnStr[2]}}</span> 
                </mo-tab-label>
                <mo-tab-label idx="1" v-show="lv_ReturnStr[3] !== ''" @click="fn_OpenMSPPM892P()">
                  <span class="tit">기간</span>
                  <span class="txt" v-if="lv_ReturnStr[3] === '전체'">{{lv_ReturnStr[3]}}</span> 
                  <span class="txt" v-else>{{lv_ReturnStr[4] + ' ~ ' + lv_ReturnStr[5]}}</span> 
                </mo-tab-label>
              </mo-tab-box>
            </ur-box-container>
            <!-- 검색결과 카테고리 출력 //-->

            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-check-sum">
              <mo-list-item>
                <!-- <mo-checkbox v-model="isOneOrMoreCheck" size="small" @click="fn_CheckAll()" :disabled="isEmptyView || checkDisabled"/> -->
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="sum" v-if="checkCount === 0">총 <strong class="crTy-blue3">{{totCnt}}</strong> 건</span>
                    <span class="sum" v-else><strong class="crTy-blue3">{{checkCount}}</strong> 명 선택</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>
          </template>
          <!-- stickey 영역 //-->

          <!-- 스크롤 List -->
          <template #scroll>
            <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-list-area msp-list-area">      
                <ur-box-container v-if="!isEmptyView" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-list">
                  <mo-list :list-data="pMCoalInsrContVO">
                    <template #list-item="{ index, item }">
                      <mo-list-item :class="{'on' : item.checked}" @click.native.stop="fn_OnClickListItem(index)"> <!-- on item이 체크되어 선택되었음을 표시  --->
                        <!-- <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItemCount(item)" :disabled="isEmptyView || checkDisabled" :ref="`chk${index}`"/>  -->
                        <div class="list-item__contents">
                          <!-- 피보험자명 -->
                          <div class="list-item__contents__title fexJsCtTy1"> 
                            <span class="name txtSkip maxW100 fexInt" @click.stop="fn_OpenMSPCM115M(item.insrdNm)">{{item.insrdNm}}</span>
                            <div class="bage-area mr5">
                              <!-- 모집경로 -->
                              <mo-badge v-if="item.contScCd === '01'" class="badge-sample-badge lightblue sm" :text="item.contScNm" shape="status">{{item.contScNm}}</mo-badge>
                              <mo-badge v-if="item.contScCd === '02'" class="badge-sample-badge lightyellow sm" :text="item.contScNm" shape="status">{{item.contScNm}}</mo-badge>
                            </div>
                          </div>
                          <!-- 생년월일, 피보험자성별, 피보험자연락처 -->
                          <div class="list-item__contents__info">
                            <span>{{$commonUtil.dateDashFormat(item.dob)}}</span><em>|</em>
                            <span>{{item.sxdsNm}}</span><em>|</em>
                            <span>{{$commonUtil.dateDashFormat(item.contYmd)}}</span>
                          </div>
                          <!-- 상품명 -->
                          <div class="list-item__contents__title fwn linkTy">
                            <span class="fs16rem txtSkip" @click.stop="fn_ContNoDecrypt(item)">{{item.prdtMclsfNm}}</span>
                          </div>
                        </div>
                      </mo-list-item>
                    </template>
                  </mo-list>
                </ur-box-container>

                <!-- NoData 영역  -->
                <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data mb200">
                  <mo-list-item>
                    <div class="list-item__contents">
                      <div class="list-item__contents__info">
                        <span>데이터가 없습니다.</span>
                      </div>
                    </div>
                  </mo-list-item>
                </ur-box-container>

                <!-- Toast -->
                <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

              </ur-box-container>
            </ur-box-container>
          </template>
          <!-- 스크롤 List -->
        </mo-collapsing-header-view>
      </div>
     </ur-box-container>

    <!-- 계약 로딩 표시 -->
    <div class="msp loading--dim msp-in-page" v-show="isReading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>
    
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPPM892P from '@/ui/pm/MSPPM892P' // 제휴계약 상세조회
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPPM891M',
  // 현재 화면 ID
  screenId: 'MSPPM891M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPPM892P,
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      lv_HeaderviewScrollCompID: '',
      toastMsg: '', // 문자발송 toast 문구

      // 제어 변수
      isMngr: false,            // 지점장 여부
      isInit: false, // 검색 초기화 여부
      isOneOrMoreCheck: false,  // 전체선택
      isFirst: false,           // 초회 검색 여부
      checkDisabled: false,     // 지점장 체크박스 선택 가능여부
      isLoading: false,
      isEmptyView: false,       // 데이터 없음 화면

      // 데이터 변수
      pOrgData891M: {}, // 최근 조직 조회 데이터
      popup892: {}, // MSPPM892P 상세조회
      lv_ReturnStr: [], // 상세조회조건 텍스트 배열
      // 제휴계약 리스트 조회
      nowDate: this.$pmUtil.getCurrentDate(),
      pMCoalInsrContVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {}, // 사용자정보 obj
      lv_cnsltNo: '', // 컨설턴트 번호
      lv_inputParam: { // 서비스호출 Obj
        hofOrgNo: '', // 사업부조직번호
        dofOrgNo: '', // 지역단조직번호
        fofOrgNo: '', // 지점조직번호
        cnsltNo: '', // 컨설턴트번호
        orgNo: '',  // 지점조직번호(TSSPM066D)
        clmnyCnsltNo: '',
        insrdNm: '',    // 피보험자명
        contrNm: '',   // 계약자명
        prdtNm: '',    // 상품명
        contYmd01: '', // 계약일자 시작
        contYmd02: '', // 계약일자 종료
      },
      signDate: '1',  // 계약일자 구분
      totCnt: 0, // 누적 총 조회건수
      // 페이징
      stndKeyList: [],  // 더보기 key
      stndKeyVal: '',
      pageRowCnt: '20', // 페이지 카운트
      lv_TmpCustno: '',
      itemCount: 0,

      isReading: false,
      loadingMsg: '고객 정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.'
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.pMCoalInsrContVO.filter(item => item.checked)
    },
    checkCount () {
      return this.pMCoalInsrContVO.filter(item => item.checked).length
    },
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData891M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    }
  },

  /** watch 정의 영역 */
  watch: {
    checkCount () {
      if (this.checkCount === this.pMCoalInsrContVO.length && this.pMCoalInsrContVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호

    this.lv_inputParam.dofOrgNo = this.userInfo.onpstDofNo // 지역단조직번호
    this.lv_inputParam.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호
    this.lv_inputParam.HofOrgNo = this.userInfo.onpstHofOrgNo // 소속사업부조직번호

    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    // 제휴계약 리스트 조회
    this.fn_Submit()
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    // 스크롤 애니메이션 instance 선언
    this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
  },


  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        this.$router.go(-1)
      }
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {
      let lv_Vm = this
      
      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)

      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (!_.isEmpty(this.pOrgData891M) && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData891M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      lv_Vm.stndKeyVal = ''
      lv_Vm.stndKeyList = []
      lv_Vm.totCnt = 0
      lv_Vm.pMCoalInsrContVO = [] // 인피니트 바인딩 데이터
      lv_Vm.isEmptyView = false // 데이터가 없습니다 visible 여부
      lv_Vm.fn_DataSet() // 조회 파라미터 세팅

      lv_Vm.fn_GetService() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_DataSet
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSet () {
      let lv_Vm = this
      
      lv_Vm.lv_inputParam.clmnyCnsltNo = lv_Vm.userInfo.userId
      // 지점장 조회시 구분
      if (!_.isEmpty(lv_Vm.pOrgData891M) && lv_Vm.isMngr === true) {
        lv_Vm.lv_inputParam.clmnyCnsltNo  = lv_Vm.pOrgData891M.cnslt.key // 선택한 컨설턴트 번호
        lv_Vm.lv_inputParam.orgNo = lv_Vm.pOrgData891M.fofOrg.key   // 선택한 지점조직번호
      } else {
        lv_Vm.lv_inputParam.orgNo = lv_Vm.userInfo.onpstFofOrgNo // 지점조직번호
      }

      if (lv_Vm.signDate === '1') {
        lv_Vm.lv_inputParam.contYmd01 = ''
        lv_Vm.lv_inputParam.contYmd02 = ''
      } else {
        if (lv_Vm.lv_inputParam.contYmd01.indexOf('-') >= 0) {
          lv_Vm.lv_inputParam.contYmd01 = lv_Vm.lv_inputParam.contYmd01.replace(/-/gi, '').substr(0, 8) // 조회시작일자
        }
        if (lv_Vm.lv_inputParam.contYmd02.indexOf('-') >= 0) {
          lv_Vm.lv_inputParam.contYmd02 =lv_Vm.lv_inputParam.contYmd02.replace(/-/gi, '').substr(0, 8) // 조회종료일자
        }
      }

      var englishOrKorean = /[A-Za-z]/
      if (!this.$bizUtil.isEmpty(this.lv_inputParam.insrdNm)) {
        if (englishOrKorean.test(this.lv_inputParam.insrdNm)) {
          this.lv_inputParam.insrdNm = this.lv_inputParam.insrdNm.toUpperCase()
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 서비스 호출(제휴계약 리스트 조회)
     ******************************************************************************/
    fn_GetService () {
      if (this.stndKeyVal === 'end') return

      const trnstId = 'txTSSPM40S2'
      const auth = 'S'
      const lv_Vm = this
      let inputData = lv_Vm.lv_inputParam
      let confirm = this.getStore('confirm')

      lv_Vm.isLoading = true

      return this.post(this, inputData, trnstId, auth)
        .then(this.fn_GetContractInfoHandler)
    },
    /******************************************************************************
    * Function명 : fn_GetContractInfoHandler
    * 설명       : 데이터를 가져와 필요한 처리와 화면에 뿌려주는 함수
    *
   ******************************************************************************/
    fn_GetContractInfoHandler (response) {
      const lv_Vm = this

      if (!response || !response.body) {
        return
      }
      // 서버 데이터 가져오기
      if (response.body.pMCoalInsrContVO !== null) {
        let responseList = response.body.pMCoalInsrContVO

        responseList.forEach((item) => {
          // item.markUpContNo = '*' + item.contNo.substr(item.contNo.length - 4, 4)
          if (item.contScCd === '01') {
            item.contScNm = '본인'
          } else {
            item.contScNm = '타인'
          }
          if (item.sxdsCd === '1') {
            item.sxdsNm = '남자'
          } else if (item.sxdsCd === '2') {
            item.sxdsNm = '여자'
          }
          if (item.mailRcpplCd === '11') {
            item.Addr = item.homBasAddr
          } else if (item.mailRcpplCd === '12') {
            item.Addr = item.jobpBasAddr
          }

          // 체크 변수 적용
          item.checked = false
          // 상품명 18자까지 표현
          // item.prdtMclsfNm = item.prdtMclsfNm.length > 18? item.prdtMclsfNm.substr(0,18) + '...': item.prdtMclsfNm

          this.pMCoalInsrContVO.push(item)
        })

        // 조회 총 카운트
        lv_Vm.totCnt = lv_Vm.pMCoalInsrContVO.length

        // 더보기 키값
        lv_Vm.stndKeyList = []
        if (response.trnstComm.stndKeyList[0]) {
          lv_Vm.stndKeyList.push(response.trnstComm.stndKeyList[0])
          if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
          // 다음페이지 데이터 존재안함(마지막 페이지)
            lv_Vm.stndKeyVal = 'end'
          } else {
          // 다음페이지 데이터 존재함
            lv_Vm.stndKeyVal = 'more'
          }
        }

        if (responseList.length === 0) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
        } else {
          lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
        }
      } else {
        lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
      }
      
      lv_Vm.isLoading = false
    },

    /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 인피니트 로딩데이터
    ******************************************************************************/
    fn_LoadingData () {
      let lv_Vm = this

      if (!lv_Vm.isLoading) {
        // 더보기 시 전체체크 되어있으면 전체체크 해제
        if (lv_Vm.isOneOrMoreCheck && lv_Vm.stndKeyVal !== 'end') {
          lv_Vm.isOneOrMoreCheck = false
        }

        lv_Vm.fn_GetService()
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      let lv_Vm = this
      this.$nextTick(() => {
        if (lv_Vm.pMCoalInsrContVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in lv_Vm.pMCoalInsrContVO) {
            if (lv_Vm.isOneOrMoreCheck) {
              lv_Vm.pMCoalInsrContVO[n].checked = true
            } else {
              lv_Vm.pMCoalInsrContVO[n].checked = false
            }
          }
          this.fn_CheckSelectedItemCount()
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItemCount
     * 설명       : 체크박스 클릭 핸들러
     *             - 선택한 리스트 카운트를 체크한다. 30명 이상 문자전송 불가
     ******************************************************************************/
    fn_CheckSelectedItemCount () {
      let lv_Vm = this

      // 체크 시 BottomSheet 표시
      for (let i in lv_Vm.selectItems) {
        lv_Vm.selectItems[i].custId = lv_Vm.selectItems[i].contrCustId
      }
      this.selectItems.busnCd = 'PM'
      lv_Vm.$cmUtil.setSelectedCustList(lv_Vm, lv_Vm.selectItems)
    },

    /*********************************************************
     * Function명 : fn_OpenMSPCM115M
     * 설명       : 피보험자명 선택 시, 전체고객화면의 해당 고객명 검색결과로 이동
     *********************************************************/
    fn_OpenMSPCM115M (pSearchKeyword) {
      
      // 전체고객에서 {0} 고객을 검색합니다.
      let msg = this.$t('pm').EPMC063.replace('{0}', pSearchKeyword)

      // Alert 로 화면이동여부를 확인해준다.
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {

        properties: {
          title: '',
          content: msg,
          title_pos_btn: '확인',
          title_neg_btn: '취소'
        },

        listeners: {
          onPopupConfirm: () => {
              let tmpObj = {}
              tmpObj.pSearchKeyword = pSearchKeyword

              // 통합 접촉고객(MSPCM121M)
              this.$router.push({name: 'MSPCM115M', params: tmpObj})
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
            }
        }
      })
      
    },

    /******************************************************************************
     * Function명 : fn_MoveMSPPM931M
     * 설명       : 보험계약기본사항 메인으로 이동(2차 개발)
     *              callerId : 송신화면, chnlCustId : 채널아이디 ,
     *              viewId : 수신화면(기본값: null || 'MSPPM932D'), 
     *              [!!필수값!!] cnsltNo - 컨설턴트번호, contNo  - 계약번호
     * 작성일     : 23.03.23 
     * 작성자     : 서영화 
     * @param {String} contNo     계약번호
     * @param {String} chnlCustId 채널아이디 
     ******************************************************************************/
    fn_MoveMSPPM931M (response) {
      if (!response || !response.body) {
        return
      }

      let contInfoObj = {
        viewId: null,                         
        callerId: this.$options.screenId,     
        chnlCustId: response.body.chnlCustId,               
        cnsltNo: this.lv_cnsltNo,               
        contNo: this.lv_TmpCustno,                       
      }

      this.$router.push({name: 'MSPPM931M', params: contInfoObj})
    },

    /*********************************************************
     * Function명 : fn_OpenMSPPM892P
     * 설명       : 상세조회조건 팝업 호출
     * @param {Object} - 설정된 상세조회조건
     * @return {Object} - 설정된 상세조회조건
     *********************************************************/
    fn_OpenMSPPM892P () {
      this.popup892 = this.$bottomModal.open(MSPPM892P, {
        properties: {
          pIsMngr: this.isMngr, // 관리자여부
          pOrgData: _.cloneDeep(this.pOrgData891M), // 조직정보
          pInsrdNm: this.lv_inputParam.insrdNm,     // 피보험자명
          pContrNm: this.lv_inputParam.contrNm,     // 계약자명
          pPrdtNm: this.lv_inputParam.prdtNm,       // 상품명
          pSignDate: this.signDate,                 // 계약일자 구분
          pContYmd01: this.lv_inputParam.contYmd01, // 계약일자 시작
          pContYmd02: this.lv_inputParam.contYmd02, // 계약일자 종료
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup892)

            // 조회조건 텍스트 세팅
            this.lv_ReturnStr = pData.srchOpt

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData891M = pData.rtnData.pOrgData
            // 나머지 조회조건 세팅
            this.lv_inputParam.insrdNm = pData.rtnData.pInsrdNm
            this.lv_inputParam.contrNm = pData.rtnData.pContrNm
            this.lv_inputParam.prdtNm = pData.rtnData.pPrdtNm
            this.lv_inputParam.contYmd01 = pData.rtnData.pContYmd01
            this.lv_inputParam.contYmd02 = pData.rtnData.pContYmd02
            this.signDate = pData.rtnData.pSignDate
            // 검색 초기화 여부
            this.isInit = pData.isInit
            // 조회조건 텍스트 세팅
            this.lv_ReturnStr = pData.srchOpt

            // 제휴계약 리스트 조회
            this.fn_Submit()
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.popup892)
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_GetClientinfo
     * 설명       : 선택 고객의 채널고객ID 취득
     ******************************************************************************/
    fn_GetClientinfo (contNo) {
      if (this.selectItems.length > 1) return // 선택한 고객이 1명 이상이면 return
      this.lv_TmpCustno = contNo
      this.$pmUtil.fn_getClientinfoSMS(this.lv_TmpCustno, this.lv_cnsltNo, this.fn_MoveMSPPM931M, this.$options.screenId)
    },

    /******************************************************************************
     * Function명 : fn_ContNoDecrypt
     * 설명       : 계약번호 복호화
     * 작성자     : 엄기문
     ******************************************************************************/
    async fn_ContNoDecrypt(item) {
      let prcContNo = ''
      if(!this.$bizUtil.isEmpty(item.contNoEnc)) {
        prcContNo = await this.$commonUtil.getDecryptCall(this,[item.contNoEnc])
        prcContNo = prcContNo[0]
      }  
      this.fn_GetClientinfo(prcContNo)
    },

    /******************************************************************************
      * Function명  : fn_SetProgressMsgPop
      * 설명        : 로딩 메시지 설정
      * Params      : pShowFlag - Progress 화면 설정 Flag
      ******************************************************************************/
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.$route.query?._isMenuItem) {
                this.$router.push({name: 'MSPBC002M'})
                return
              }
              this.$router.go(-1)
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /**
     * @description 체크박스 이외의 영역 클릭했을 때, 체크 활성화 및 비활성화 한다.
     * @param       {Number} index 리스트 데이터의 index
     */
    fn_OnClickListItem(index) {
      // this.$refs[`chk${index}`].onClick()
    },


  }
}
</script>
