/*
 * 업무구분: 계약관리
 * 화 면 명: MSPPM892P
 * 화면설명: 제휴계약 상세조회조건
 * 접근권한: 
 * 작 성 일: 2022.12.16
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container :show-title="true" title="제휴계약 상세조건" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <!-- 조직관련 COMPONENT : 관리자 접근시 노출 -->
        <ur-box-container direction="column" alignV="start" class="ns-sch-area">
          <SearchCondOrgCM ref="refSearchOrg" v-if="lv_IsMngr" v-model="lv_OrgSearchData" :isFofType="1" :pOrgData="lv_OrgSearchData" @selectResult="fn_SlectCnsltResultPM"/>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-input-box mb20">
          <mo-text-field v-model="lv_InsrdNm" @keyup.enter="fn_Search" underline placeholder="피보험자명을 입력하세요." clearable/>  
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-input-box mb20">
          <mo-text-field v-model="lv_ContrNm" @keyup.enter="fn_Search" underline placeholder="계약자명을 입력하세요." clearable/>  
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-input-box mb20">
          <mo-text-field v-model="lv_PrdtNm" @keyup.enter="fn_Search" underline placeholder="상품명을 입력해주세요." clearable/>  
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-input-box mb20">
          <!-- 전체,기간 chip 버튼 유형 타입 2022-12-19 수정 (컴포넌트 chip 버튼 유형 확정) -->
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_SignDate" class="chip-type-wrap">
              <mo-segment-button v-for="(item, idx) in lv_SignDates" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
            <!-- 기간 선택 -->
            <div class="ns-date-picker multi mt20" v-if="lv_SignDate === '2'"> 
              <mo-date-picker v-model="lv_ContYmd01" /><span>~</span>
              <mo-date-picker v-model="lv_ContYmd02" />
            </div>
            <!-- 기간 선택 //-->
          </ur-box-container>
          <!-- 전체,기간 chip 버튼 유형 타입 2022-12-19 수정 (컴포넌트 chip 버튼 유형 확정) //-->
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ResetBtnClick">초기화</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Search">조회</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/*******************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************/
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import pmConstants from '@/config/constants/pmConstants.js'
import moment from 'moment'

export default {
  /*****************************************************************************
   * Vue 파일 속성 정의 영역
   ****************************************************************************/
  name: 'MSPPM892P',
  screenId: 'MSPPM892P',
  components: {
    SearchCondOrgCM,
    MspBottomSelect
  },
  props: {
    pIsMngr: Boolean, // 조직combox 권한자여부
    pOrgData: {
      type: Object,
      default: {}
    },
    pInsrdNm: String,   // 피보험자명
    pContrNm: String,   // 계약자명
    pPrdtNm: String,    // 상품명
    pSignDate: String,  // 계약일자 구분
    pContYmd01: String, // 계약일자 시작
    pContYmd02: String, // 계약일자 종료
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /*****************************************************************************
   * Life Cycle 함수 정의 영역
   ****************************************************************************/
  created () {
    // 스크롤영역 ID 설정
    this.scrollCompID = 'scroll_' + this.$options.screenId
    console.log('this.scrollCompID', this.scrollCompID)

    this.userInfo = this.getStore('userInfo').getters.getUserInfo

    // 부모화면으로부터 전달받은 파라미터를 설정
    this.lv_IsMngr = this.$props.pIsMngr // 관리자여부
    this.lv_OrgSearchData = _.cloneDeep(this.$props.pOrgData) // 조직정보
    this.lv_InsrdNm = _.cloneDeep(this.$props.pInsrdNm)
    this.lv_ContrNm = _.cloneDeep(this.$props.pContrNm)
    this.lv_PrdtNm = _.cloneDeep(this.$props.pPrdtNm)
    this.lv_SignDate = _.cloneDeep(this.$props.pSignDate)
    if (this.$bizUtil.isEmpty(this.$props.pContYmd01)) this.lv_ContYmd01 = this.$pmUtil.getCurrentDate('YYYY-MM-DD')
    else this.lv_ContYmd01 = moment(this.$props.pContYmd01).format('YYYY-MM-DD').toString()
    if (this.$bizUtil.isEmpty(this.$props.pContYmd02)) this.lv_ContYmd02 = this.$pmUtil.getCurrentDate('YYYY-MM-DD')
    else this.lv_ContYmd02 = moment(this.$props.pContYmd02).format('YYYY-MM-DD').toString()
  },
  mounted () {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /*****************************************************************************
   * 화면 전역 변수 선언 영역
   ****************************************************************************/
  data () {
    return {
      scrollCompID: '', // 스크롤 컴포넌트ID
      userInfo: {},
      lv_ReturnStr: [], // 조회조건 텍스트 배열
      lv_InsrdNm: '', // 피보험자명
      lv_ContrNm: '', // 계약자명
      lv_PrdtNm: '', // 상품명
      // 계약일자
      lv_SignDate: '1',
      lv_SignDates: pmConstants.SIGN_DATE_TYPE_CD,
      lv_ContYmd01: '',
      lv_ContYmd02: '',
      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부
      lv_OrgSearchData: {}, // 조직정보
      lv_RtnOrgSearchData: {}, // 조직정보
      lv_IsMngr: false, // 관리자여부
      lv_AlertPop: '' // bottom alert component
    }
  },
  /*****************************************************************************
   * 사용자 함수 정의 영역
   ****************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultPM()
     * 설명       : 조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     * allChk: 조회버튼 활성화/비활성화 여부
     ******************************************************************************/
    fn_SlectCnsltResultPM (pData) {
      if ( this.isShowCnslt ) {
        // this.lv_OrgSearchData = pData
        this.lv_RtnOrgSearchData = pData
        this.allChk = true
      }
      this.isShowCnslt = true
    },

    /******************************************************************************
     * Function명 : fn_ResetBtnClick
     * 설명       : 상세조회 조건 초기화
     *              (상세조회 내 초기화 버튼 클릭시 호출)
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_ResetBtnClick (e) {
      // 조직
      this.lv_OrgSearchData = {}
      this.lv_RtnOrgSearchData = {}
      this.lv_InsrdNm = '' // 피보험자명 검색어 초기화
      this.lv_ContrNm = '' // 계약자명 검색어 초기화
      this.lv_PrdtNm = '' // 상품명 검색어 초기화
      // 계약일자 초기화
      this.lv_SignDate = this.lv_SignDates[0].value 
      this.lv_ContYmd01 = this.$pmUtil.getCurrentDate('YYYY-MM-DD') 
      this.lv_ContYmd02 = this.$pmUtil.getCurrentDate('YYYY-MM-DD') 
    },

    /******************************************************************************
     * Function명 : fn_Search
     * 설명       : 상세조회조건을 설정 후 부모화면에의 조회함수 호출
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_Search () {
      // 유효성 체크
      if ( !this.fn_ValidationCheck() ) return false

      // 초기화 여부
      let isInit = false
      if (this.$bizUtil.isEmpty(this.lv_ContrNm) && this.$bizUtil.isEmpty(this.lv_InsrdNm) && this.$bizUtil.isEmpty(this.lv_PrdtNm) 
          && this.lv_SignDate === this.lv_SignDates[0].value && this.lv_ContYmd01 === this.$pmUtil.getCurrentDate('YYYY-MM-DD') 
          && this.lv_ContYmd02 === this.$pmUtil.getCurrentDate('YYYY-MM-DD') ) {
        if (this.lv_IsMngr) {
          if (this.userInfo.userId === this.lv_RtnOrgSearchData.cnslt.key) isInit = true
          else isInit = false
        } else isInit = true
      }

      // 조회조건 텍스트 배열
      this.lv_ReturnStr = [] // 초기화
      // 조회조건: 피보험자명, 계약자명, 상품명, 계약일자 구분, 계약일자 시작, 계약일자 종료
      this.lv_ReturnStr = [this.lv_InsrdNm, this.lv_ContrNm, this.lv_PrdtNm, this.lv_SignDates.find(e => e.value === this.lv_SignDate).text, this.lv_ContYmd01, this.lv_ContYmd02]

      // return data set
      let t_RtnData = {
        pIsMngr: this.lv_IsMngr, // 관리자여부
        // pOrgData: _.cloneDeep(this.lv_OrgSearchData), // 조직정보
        pOrgData: _.cloneDeep(this.lv_RtnOrgSearchData),
        pInsrdNm: this.lv_InsrdNm,      // 피보험자명
        pContrNm: this.lv_ContrNm,      // 계약자명
        pPrdtNm: this.lv_PrdtNm.trim(), // 상품명
        pSignDate: this.lv_SignDate,    // 계약일자 구분
        pContYmd01: this.lv_ContYmd01,  // 계약일자 시작
        pContYmd02: this.lv_ContYmd02,  // 계약일자 종료
      }
      this.$emit('onPopupSearch', {rtnData: t_RtnData, srchOpt: this.lv_ReturnStr, isInit: isInit})
    },

    /******************************************************************************
     * Function명 : fn_ValidationCheck
     * 설명       : 상세조회조건 유효성 체크
     * @param {none}
     * @return {Boolean} - true: 유효함, false: 유효하지 않음
     ******************************************************************************/
    fn_ValidationCheck () {
      // 조직 콤보박스 권한자인 경우 컨설턴트 입력여부 체크
      if ( this.lv_IsMngr ) {
        if ( _.isEmpty(this.lv_RtnOrgSearchData) || this.$bizUtil.isEmpty(this.lv_RtnOrgSearchData.cnslt) ||
          this.lv_RtnOrgSearchData.cnslt.key === '1' ||
          this.lv_RtnOrgSearchData.cnslt.key === '0' ) {
          this.getStore('confirm').dispatch('ADD', '정보보호 요청 대상건 입니다.')  // EPMC005
          return false
        }
      }

      // 날짜 입력 체크
      if (this.lv_SignDates[0].value === '2') {
        if (this.lv_ContYmd01 > this.lv_ContYmd02) {
          this.$pmUtil.getFDPConfirm('s', this.$t('pm')['EPMC001'])
          return false
        }
      }
      return true
    },

    /******************************************************************************
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.ui-frame__scroll-position-top .pm902p-top-btn-display-none {
  display: none;
}
</style>
